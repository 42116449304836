import React from 'react';
import classNames from 'classnames';
import Image from '../../elements/Image';

const FooterSocial = ({ className, ...props }) => {
  const classes = classNames('footer-social', className);

  return (
    <div {...props} className={`center-content pl-32 pr-32 ${classes}`}>
      <div className="footer-title footer-line">Contato</div>
      <div className="footer-tile">
        <div style={{ display: 'table-cell', height: '100%' }} className="va-c">
          <Image
            src={require('../../../assets/images/telefone.svg')}
            alt="Features tile icon 01"
            width={16}
            height={16}
            style={{
              margin: '10px 10px 10px 10px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
        </div>
        <span className="footer-text" style={{ display: 'table-cell' }}>
          (11) 5555-3366{' '}
        </span>
      </div>
      <div className="footer-tile">
        <div style={{ display: 'table-cell', height: '100%' }} className="va-c">
          <Image
            src={require('../../../assets/images/whatsapp.svg')}
            alt="Features tile icon 01"
            width={24}
            height={24}
            style={{
              margin: '12.49px 5px 12.49px 5px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
        </div>
        <span className="footer-text" style={{ display: 'table-cell' }}>
          (11) 9 8787-4110{' '}
        </span>
      </div>
      <div className="footer-tile">
        <div style={{ display: 'table-cell', height: '100%' }} className="va-c">
          <Image
            src={require('../../../assets/images/email.svg')}
            alt="Features tile icon 01"
            width={24}
            height={24}
            style={{
              margin: '16.345px 5px 16.345px 5px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
        </div>
        <span className="footer-text" style={{ display: 'table-cell' }}>
          contato@jquant.com.br{' '}
        </span>
      </div>
      <div style={{ display: 'inline-block' }}>
        <div style={{ display: 'table-cell', height: '100%' }} className="va-c">
          <Image
            src={require('../../../assets/images/endereco.svg')}
            alt="Features tile icon 01"
            width={16}
            height={16}
            style={{
              margin: '10px 10px 10px 10px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
        </div>
        <span className="address-text" style={{ display: 'table-cell' }}>
          <span style={{ display: 'flex' }}>
            Rua Prof. Atílio Innocenti, 165
          </span>{' '}
          <span style={{ display: 'flex' }}>Itaim Bibi</span>{' '}
          <span style={{ display: 'flex' }}>São Paulo (SP) </span>
        </span>
      </div>
    </div>
  );
};
{
  /* <ul className="list-reset">
        <li>
          <div>
            <Image
              src={require('../../../assets/images/telefone.svg')}
              alt="Features tile icon 01"
              width={24}
              height={24}
              style={{ margin: '0 0 10px' }}
            />
            <div className="va-c" style={{ display: 'inline-flex' }}>
              JQuant
            </div>
          </div>
          <div>
            <Image
              src={require('../../../assets/images/endereco.svg')}
              alt="Features tile icon 01"
              width={24}
              height={24}
            />
          </div>
          <div>
            <Image
              src={require('../../../assets/images/email.svg')}
              alt="Features tile icon 01"
              width={32}
              height={32}
            />
          </div>
        </li>
      </ul> */
}

export default FooterSocial;
