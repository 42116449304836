import React from 'react';
import classNames from 'classnames';
// import { Link } from 'react-router-dom';
import Image from '../../elements/Image';

const Logo = ({ className, ...props }) => {
  const classes = classNames('brand', className);

  return (
    <div {...props} className={classes}>
      <h1 className="m-0">
        {/* <Link to="/"> */}
        <div className="footer-bottom space-between text-xxs invert-order-desktop">
          <div className="footer-copyright">Powered by</div>
        </div>
        <Image
          src={require('../../../assets/images/logo_jquant.png')}
          alt="Open"
          width={96}
          height={96}
        />
        {/* </Link> */}
      </h1>
    </div>
  );
};

export default Logo;
